<style scoped>
div {
  background: rgba(0, 0, 0, 0) !important;
}
</style>

<template>
  <AppButtonCollapse
    icon="mdi-book-open-page-variant-outline"
    messageHidden="Literatur anzeigen"
    messageNotHidden="Literatur verstecken"
    elevation="0"
  >
    <div>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>
                Gaidoschik, M. (2010).
                <i
                  >Wie Kinder rechnen lernen – oder auch nicht. Eine empirische
                  Studie zur Entwicklung von Rechenstrategien im ersten
                  Schuljahr.
                </i>
                Frankfurt am Main: Peter Lang.
              </td>
            </tr>
            <tr>
              <td>
                Götze, D., Selter, Ch. & Zannetin, E. (2019).<i
                  >Das KIRA-Buch: Kinder rechnen anders. Verstehen und Fördern
                  im Mathematikunterricht.</i
                >
                Hannover: Kallmeyer.
              </td>
            </tr>
            <tr>
              <td>
                Götze, D. (2015).<i>
                  Sprachförderung im Mathematikunterricht.</i
                >
                Berlin: Cornelsen.
              </td>
            </tr>
            <tr>
              <td>
                Krauthausen, G. (2018).
                <i>Einführung in die Mathematikdidaktik-Grundschule.</i>
                Heidelberg: Springer Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                Padberg, F. & Benz, Ch. (2021).
                <i>Didaktik der Arithmetik. fundiert, vielseitig, praxisnah. </i
                >Berlin/Heidelberg: Springer Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                Selter, Ch. & Zannetin, E. (2018).
                <i
                  >Mathematik unterrichten in der Grundschule. Inhalte -
                  Leitideen - Beispiele.
                </i>
                Seelze: Kallmeyer.
              </td>
            </tr>
            <tr>
              <td>
                Wittmann, e. Ch.; Müller, G. N.; Nührenbörger, M.; Schwarzkopf,
                R. (2021).
                <i>Das Zahlenbuch 1. Didaktischer Kommentar.</i> Stuttgart:
                Klett.
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </AppButtonCollapse>
</template>

<script>
import AppButtonCollapse from "@/common/AppButtonCollapse";

export default {
  name: "AppLiteraturSWV",
  components: {
    AppButtonCollapse,
  },
};
</script>
