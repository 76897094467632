<template>
  <div>
    <h2 class="faledia-caption">Darstellungen vernetzen</h2>
    <v-card outlined>
      <v-card-title>Was sollen die Schüler:innen lernen?</v-card-title>
      <v-card-text>
        Die Kinder können...
        <ul>
          <li>
            Darstellungsmittel zur Veranschaulichung einer Rechenoperation
            nutzen. (Anschauungsmittel)
          </li>
          <li>
            Darstellungsmittel für vorteilhaftes Rechnen (z.B. durch
            Ableitungsstrategien) nutzen. (Erarbeitungsmittel)
          </li>
          <li>
            Ableitungsstrategien und Zahlbeziehungen versprachlichen.
          </li>
          <li>Raumlagebegriffe auf die Einspluseinstafel anwenden.</li>
        </ul>
      </v-card-text>
    </v-card>
    <br />
    <p>
      Neben dem Wissen um die Grundvorstellungen sollten Lehrkräfte aber auch
      Wissen zum Wechsel zwischen den Darstellungsformen haben. Die
      Darstellungsformen lassen sich dabei in vier Kategorien unterscheiden, wie
      die folgende Abbildung zeigt:
    </p>

    <div class="image">
      <AppExpandableImage
        contain
        :src="require('@/assets/epe/Darstellungsformen.png')"
        elementId="epe_darstellungsformen.png"
        parentId="epe"
      >
      </AppExpandableImage>
    </div>
    <p style="text-align: right">
      entnommen aus
      <i
        ><a href="https://mahiko.dzlm.de/node/46"
          >https://mahiko.dzlm.de/node/46</a
        ></i
      >
    </p>

    <p>
      In Bezug auf das Einspluseins können die Rechenoperationen besonders
      gewinnbringend am Zwanzigerfeld veranschaulicht werden. Es ist notwendig,
      die Plättchen als Anschauungs- und Erarbeitungsmittel anzuerkennen und
      diese selbstverständlich im Unterrichtsalltag einzubinden. Denn Material
      ist nicht nur für Kinder mit Rechenschwierigkeiten als Verständnishilfe geeignet, sondern
      ist grundsätzlich für alle Kinder sinnvoll, um Vorstellungen aufzubauen
      oder mentale Operationen zu verdeutlichen (Wittmann et al., 2021).
    </p>
    <p>
      Auch die Rechengesetze können über ein Operieren mit Plättchen verstanden
      werden, obwohl sie nicht explizit an die Schüler:innen vermittelt werden.
    </p>
    <div class="image">
      <AppExpandableImage
        contain
        :src="require('@/assets/epe/DW_an_Beispielen.png')"
        elementId="epe_dw_an_beispielen.png"
        parentId="epe"
      >
      </AppExpandableImage>
    </div>
    <p style="text-align: right">
      entnommen aus
      <i
        ><a href="https://mahiko.dzlm.de/node/47"
          >https://mahiko.dzlm.de/node/47</a
        ></i
      >
    </p>

    <p>
      In der Auseinandersetzung mit der Einspluseinstafel begegnen den Lernenden
      Raumlagebegriffe wie „rechts“ und „links“, aber auch „Diagonale“ bzw.
      „Schräge“. Da diese Begriffe nicht Gegenstand der Alltagswelt der Kinder
      sind, müssen diese im Unterricht explizit thematisiert und
      ausdifferenziert werden (vgl. Götze, 2015).
    </p>
    <p>
      Dasselbe gilt für die Arbeit mit dem Gleichheitszeichen: Da das
      Einspluseins meist die erste Konfrontation mit dem Gleichheitssymbol
      bedeutet, muss der Umgang damit eindringlich thematisiert werden. Hier ist
      auch die besondere Sprechweise hervorzuheben, mit der die Kinder diesem
      begegnen.
    </p>
    <AppLiteraturEPE />
    <AppBottomNavEPE
      next="/einspluseins/uebergang"
      back="/einspluseins/hintergrundwissen/aufgabenbeziehungen"
    />
  </div>
</template>

<script>
import AppBottomNavEPE from "@/common/AppBottomNavEPE";
import AppLiteraturEPE from "@/common/AppLiteraturEPE";
import AppExpandableImage from "@/common/AppExpandableImage";

export default {
  components: {
    AppBottomNavEPE,
    AppLiteraturEPE,
    AppExpandableImage,
  },
};
</script>

<style scoped>
.image {
  max-width: 50%;
  margin-inline: auto;
  background-color: white;
}
</style>
