import { render, staticRenderFns } from "./AppLiteraturEPE.vue?vue&type=template&id=738eba02&scoped=true&"
import script from "./AppLiteraturEPE.vue?vue&type=script&lang=js&"
export * from "./AppLiteraturEPE.vue?vue&type=script&lang=js&"
import style0 from "./AppLiteraturEPE.vue?vue&type=style&index=0&id=738eba02&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "738eba02",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VSimpleTable})
